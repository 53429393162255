#portfolio-section {
  padding: 40px 20px;
  color: #e0e0e0; /* Light text */
  margin-bottom: 30px;
}

#portfolio-section h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #ffffff;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.project-item {
  display: flex;
  flex-direction: column;
  background-color: #242243; /* Dark card background for a sleek look */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.project-image img {
  width: 100%;
  height: 200px; /* Fixed height for images */
  object-fit: cover;
}

.project-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.project-content h3 {
  margin-top: 0;
}

.project-description {
  margin-bottom: 20px;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.tech-tag {
  display: flex;
  font-size: 13px;
  align-items: center;
  padding: 5px 10px; /* Increased padding to fill more space */
  background-color: transparent;
  border: 1px solid #FFFFFF; /* White outline */
  border-radius: 24px; /* Rounded corners */
  transition: transform 0.3s, border-color 0.3s;
}

.project-button {
  align-self: flex-start;
  text-decoration: none;
  color: #F08080;
  padding: 10px 15px;
  border: 2px solid #F08080;
  border-radius: 24px;
  transition: background-color 0.3s, color 0.3s;
}

.project-button:hover {
  background-color: #F08080; /* Accent color on hover */
  color: #121212; /* Dark background color for text on hover */
  transform: translateY(-2px); /* Lift on hover for depth effect */
}
