#contact-section {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

#contact-section h1 {
  color: #ffffff;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  gap: 40px; /* Space between form and info */
}

.contact-form {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
}

.form-label {
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 8px;
}

.contact-input, .message-input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.message-input {
  height: 150px;
  resize: none;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.contact-info {
  flex: 1; /* Take up remaining space */
}

.contact-info h3 {


  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact-info p {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.6;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }
}
