body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212; /* Dark background color */
  color: #E0E0E0; /* Softer white text color */
}

.landing-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  height: 100vh;
}

.landing-content {
  font-family: "Source Code Pro", monospace;
  display: flex;
  flex-direction: row; /* Ensure content aligns horizontally */
  align-items: center;
  justify-content: space-between; /* Space between landing-text and landing-svg */
  width: 80%;
  max-width: 1200px; /* Adjust max width as needed */
  padding: 0 20px; /* Adjust padding as needed */
  z-index: 2;
}

.landing-text {
  flex: 1; /* Take up remaining space */
  max-width: 600px;
}

.hello-text {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.greeting-text {
  font-size: 3rem;
  font-weight: bold;
  position: relative; /* Ensure cursor position */
  color: #ffffff; /* Pure white for greeting text */
}

.blinking-cursor {
  animation: blink-caret 0.75s step-end infinite; /* Adjust blinking speed and style */
}

.blinking-cursor.visible {
  opacity: 1;
}

.blinking-cursor.hidden {
  opacity: 0;
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: white; /* Adjust cursor color */
  }
}

.subheading-text {
  font-size: 1.5rem;
  margin-top: 10px;
}

.homepage-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center; /* Align buttons horizontally */
}

.view-work, .contact-me {
  margin: 0 10px;
  padding: 12px 24px; /* Increased padding for better touch target */
  font-size: 1rem;
  color: #F08080; /* Accent color for text */
  background-color: transparent; /* Transparent background */
  border: 2px solid #F08080; /* Border with accent color */
  border-radius: 24px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.view-work:hover {
  background-color: #F08080; /* Accent color on hover */
  color: #121212; /* Dark background color for text on hover */
  transform: translateY(-2px); /* Lift on hover for depth effect */
}

.contact-me {
  border-color: #4A90E2; /* Blue border color */
  color: #4A90E2; /* Blue text color */
}

.contact-me:hover {
  background-color: #4A90E2; /* Blue background color on hover */
  color: #121212; /* Dark background color for text on hover */
  transform: translateY(-2px); /* Lift on hover for depth effect */
}

.landing-svg {
  flex: 1; /* Take up remaining space */
  display: flex;
  justify-content: flex-end; /* Align image to the right */
  align-items: center;
}

.landing-svg img {
  width: 300px;  /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
}

/* Ensure the entire page can scroll */
body, html {
  height: 100%;
}

#home-section {
  min-height: 100vh;
}
