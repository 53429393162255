#experience-section {
  padding: 40px 20px;
  margin-bottom: 30px;
  color: #e0e0e0; /* Light text */
}

#experience-section h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #ffffff;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.timeline:before {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #F08080; /* Timeline line color */
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.timeline-item.left {
  left: 0;
}

.timeline-item.right {
  left: 50%;
  justify-content: flex-start;
}

.timeline-item.left .timeline-content {
  text-align: right;
}

.timeline-item.right .timeline-content {
  text-align: left;
}

.timeline-content {
  background-color: #242243; /* Dark card background for a sleek look */
  font-family: "Source Code Pro";
  color: #ffffff; /* Light text */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1;
  width: 80%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.timeline-item.left .timeline-content::after,
.timeline-item.right .timeline-content::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ffffff; /* Circle color */
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timeline-item.left .timeline-content::after {
  left: 100%;
  margin-left: 20px; /* Add margin to space the dot from the card */
}

.timeline-item.right .timeline-content::after {
  left: -20px;
  margin-right: 20px; /* Add margin to space the dot from the card */
}

.timeline-item.left .experience-date {
  right: calc(100% + 20px); /* Adjust position to be right beside the dot */
}

.timeline-item.right .experience-date {
  left: calc(100% + 20px); /* Adjust position to be right beside the dot */
}

.timeline-item:hover .timeline-content {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.experience-date {
  font-style: italic;
  color: #b0b0b0;
  margin-bottom: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.experience-description {
  line-height: 1.6;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .timeline-item {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .timeline-item.left, .timeline-item.right {
    left: 0;
  }

  .timeline:before {
    left: 20px;
  }

  .timeline-content {
    width: 100%;
    text-align: left;
  }

  .timeline-item.left .timeline-content::after,
  .timeline-item.right .timeline-content::after {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .experience-date {
    right: calc(100% + 10px); /* Adjust position on mobile */
    font-size: 0.8rem; /* Adjust font size for mobile */
  }
}
