/* About.css */
#about-section {
  padding: 40px 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.about-content {
  font-family: "Source Code Pro", monospace;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 40px; /* Space between cards */
  flex-wrap: wrap; /* Ensure responsiveness */
}

.about-card {
  background-color: #242243; /* Dark card background for a sleek look */
  padding: 30px;
  border-radius: 12px; /* More rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  flex: 1; /* Equal width for each card */
  min-width: 300px; /* Ensure a minimum width for cards */
  transition: transform 0.3s, box-shadow 0.3s;
  color: #FFFFFF; /* Light text color */
}

.about-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.about-card h2 {
  font-size: 1.8rem;
  color: #FFFFFF; /* Ensure header text is visible */
  margin-bottom: 15px;
}

.about-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #B0B0B0; /* Slightly lighter text for readability */
}

.skill-icons {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center;
  flex-wrap: wrap;
  gap: 10px; /* Space between icons */
}

.tech-flair {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Increased padding to fill more space */
  background-color: transparent;
  border: 1px solid #FFFFFF; /* White outline */
  border-radius: 24px; /* Rounded corners */
  transition: transform 0.3s, border-color 0.3s;
}

.tech-flair:hover {
  transform: translateY(-5px); /* Lift on hover */
  border-color: #F08080; /* Accent color on hover */
}

.tech-flair img {
  width: 40px; /* Increased icon size */
  height: 40px;
  margin-right: 10px; /* Space between icon and text */
}

.tech-flair span {
  font-size: 1rem; /* Adjusted font size */
  color: #FFFFFF; /* Text color */
}

.hobby-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between hobbies */
}

.hobby {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #FFFFFF; /* White outline */
  border-radius: 24px; /* Rounded corners */
  padding: 10px 20px; /* Adjusted padding */
  margin-bottom: 10px; /* Margin between hobbies */
  transition: transform 0.3s, border-color 0.3s;
}

.hobby:hover {
  transform: translateY(-5px); /* Lift on hover */
  border-color: #F08080; /* Accent color on hover */
}

.hobby-title {
  font-size: 1rem; /* Adjusted font size */
  margin-left: 10px; /* Space between emoji and title */
  color: #FFFFFF; /* Text color */
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    gap: 20px;
  }

  .skill-icons, .hobby-icons {
    justify-content: center;
  }

  .hobby {
    min-width: 150px; /* Adjusted minimum width for hobbies */
  }
}
