.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 70px;
  padding: 0 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow */
  backdrop-filter: blur(10px); /* Blurred background effect (for modern browsers) */
}

.navLinks {
  list-style-type: none;
  margin-right: 3%;
  padding: 0;
  display: flex;
  align-items: center; /* Center align the links vertically */
}

.left-section { 
  font-family: "Source Code Pro";
  cursor: pointer;
}

.left-section:hover { 
  color: #F08080;

}


.social-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.social-icons a {
  margin-left: 15px; /* Adjust spacing between icons */
}

.social-icons img {
  width: 30px; /* Adjust icon size as needed */
  height: 30px;
}

.navLinks li {
  margin: 0 15px; /* Adjust spacing between links */
}

.navLinks li:not(:last-child) {
  position: relative; /* Ensure relative positioning for pseudo-element */
}

.navLinks li:not(:last-child)::after {
  content: '|'; /* Add pipe character after each link */
  margin-left: 30px; /* Adjust as needed */
}

.navLinks li a {
  color: white;
  text-decoration: none;
  font-family: "Source Code Pro";
  transition: color 0.3s ease;
  cursor: pointer;
  position: relative; /* Ensure relative positioning for pseudo-element */
}

.navLinks li a:hover {
  color: #F08080;
}

.navLinks li a:hover::after {
  content: ''; /* Create pseudo-element for underline */
  position: absolute;
  bottom: -2px; /* Adjust the underline position */
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}
